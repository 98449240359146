import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import FunctinosTemplate from 'components/Templates/FunctinosTemplate';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface pathPlotProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgPathPlot: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const pathPlot = ({ location, data }: pathPlotProps) => {
  const title = 'VODA | 기능 | 패스플롯';
  const desc =
    '사용자들의 이동경로를 분석하여 전체 페이지의 유입 및 전환, 이탈 통계를 알려드립니다.';

  return (
    <Component>
      <Helmet>
        <title>VODA | 기능 | 패스플롯</title>
        <meta
          name="description"
          content="사용자들의 이동경로를 분석하여 전체 페이지의 유입 및 전환, 이탈 통계를 알려드립니다."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/functions/environmentAnalysis>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <meta
          name="naver-site-verification"
          content="062e2e63e40411827d007a80f9f6ca2b16af6702"
        />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <FunctinosTemplate
        location={location}
        title={'문구\\n필요'}
        header={{
          main: '사용자 이동경로를\\n분석하고\\n페이지의 유입, 전환, 이탈에 대한\\n통계를 확인할 수 있어요',
          sub: '예시',
        }}
        isAIvory={false}
        functionData={[
          {
            IllustImg: data.imgPathPlot.childImageSharp.fluid,
            descData: {
              title: '방문한 사용자의\\n이동경로를\\n분석해보세요 (수정 필요)',
              list: [
                '1. 전체 페이지의 유입, 전환, 이탈 분석',
                '2. 문구 추가 필요',
              ],
            },
            engTitle: 'PATHPLOT',
            korTitle: '패스플롯 (수정 필요)',
            isBackGround: true,
            isDescLeft: false,
            width: 800,
            key: '0',
          },
        ]}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default pathPlot;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-function@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgPathPlot: file(name: { eq: "img-pathplot" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
